import axios from 'axios';
import { Auth } from 'aws-amplify';

export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const IN_PROGRESS = 'IN_PROGRESS';
export const INITIAL = 'INITIAL';
export const PAGESTATE = 'PAGESTATE';

export const Request = () => {
  const leabero_client =  axios.create({});
  leabero_client.interceptors.request.use(async (config) => {
    let session = undefined;
    try {
      session = await Auth.currentSession();
    }
    catch (e) {
      console.error(e);
      return config;
    }
    const token = session.getIdToken().getJwtToken();
    config.headers.Authorization = token ? `Bearer ${token}`: '';

    // for local dev
    const user = await Auth.currentAuthenticatedUser();
    config.headers["remote-user"] = user.username; // or user.attributes.email
    return config
  });

  return leabero_client;
};

export function updateMobileFlag(val){
  return {
    type: 'core',
    mobile: val
  }
}

export function wholePageLoadEnd(redirect=null, follow=null){
  return {
    type: 'core',
    isGlobalFetching: false,
    redirect,
    follow
  }
}

export function updatePage(data){
  return {
    type: 'core',
    updatePage: data
  }
}

export function getFormErrorsFromAPIResponse(response, fields){
  const errors = {};
  for (let field of fields){
    const error = response[field];
    if(error){
      errors[field] = error;
    }
  }
  if(response.non_field_errors){
    errors._error = response.non_field_errors[0]
  }
  if(Object.keys(errors).length<1){
    errors._error = 'Unknown error occurred. Try again later.'
  }
  return errors;
}
